import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography, TextField, Box, Snackbar, CircularProgress, Paper } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { DEFAULT_SNACKBAR, DEFAULT_SNACKBAR_TIME, getError, defaultData } from "../../utils/constants";
import { formatName } from "../../utils/string";

function LegislatorUpdate() {
  const history = useHistory();
  const { id } = useParams();
  const fields = ["Chamber", "HomeValue", "title", "party", "hometown", "term", "occupation", "districtOffice", "districtPhone", "districtFax", "staff", "StateHouseAddress", "city", "state", "zip", "phone", "fax", "email", "twitter", "facebook", "instagram", "website", "_DNU_committees", "AIM", "CLT", "ELM", "GOAL", "MFA", "MAS", "MASSPIRG", "NFIB", "PP", "PM", "kv1", "kv2", "kv3", "kv4", "kv5", "kv6", "kv7", "kv8", "kv9", "kv10", "FillB", "FillR", "FillS", "FillE", "B_Balance", "Receipts", "Spending", "E_Balance", "nprimary", "ngeneral", "HomeAddress", "Age", "DateOfBirth", "PlaceOfBirth", "maritalStatus", "children", "religion", "hobbies", "education", "Previous", "VeteranService", "PoliticalOffices", "Organizations", "Priorities", "quote", "BookID"];

  const [data, setData] = useState(defaultData(fields));
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const [snackbar, setSnackbar] = useState(DEFAULT_SNACKBAR);

  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    fetchData();
  }, [id]);

  const fetchData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/legislative-update/${id}`);

      if (!res.ok) {
        throw new Error(await res.text());
      }

      setData(await res.json());
    } catch (e) {
      console.error(e);
      setError({type: "error", open: true, message: getError(e)});
    } finally {
      setIsLoading(false);
    }
  }

  const handleSubmit = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/legislative-update/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      history.push('/success');
    } catch (e) {
      console.error(e);
      setSnackbar({type: "error", open: true, message: getError(e)});
    }
  }

  const handleSnackbarClose = () => {
    setSnackbar(DEFAULT_SNACKBAR);
  }

  if (isLoading) {
    return (
      <CircularProgress />
    );
  }

  if (error) {
    return (
      <Alert severity={error.type}>
        {error.message}
      </Alert>
    );
  }

  return (<>
    <Snackbar open={snackbar.open} onClose={handleSnackbarClose} autoHideDuration={DEFAULT_SNACKBAR_TIME}>
      <Alert severity={snackbar.type}>
        {snackbar.message}
      </Alert>
    </Snackbar>

    <Box display="flex" justifyContent="space-between">
      <Typography variant="h3">
        {formatName(data.FirstName, data.MiddleName, data.LastName)}
      </Typography>
    </Box>

    <Paper className={classes.paper}>
      {fields.map((field, index) => (
        <Box key={index} mb={2}>
          <TextField fullWidth variant="outlined" id={field} label={field} value={data[field]} onChange={(e) => setData({...data, [field]: e.target.value})} />
        </Box>
      ))}
    </Paper>

    <Box mt={2} display="flex" justifyContent="flex-end">
      <Button variant="contained" color="primary" onClick={() => handleSubmit()}>Save Changes</Button>
    </Box>
  </>);
}

export default LegislatorUpdate;
