import React, { useState, useEffect } from "react";
import { Prompt, useParams, useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { Button, TextField, Box, Typography, Snackbar, CircularProgress, Paper } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { useGoogleAuth } from "../../contexts/auth";
import { DEFAULT_SNACKBAR, DEFAULT_SNACKBAR_TIME, getError } from "../../utils/constants";

function DivisionContactView() {
  const { googleUser } = useGoogleAuth();
  const history = useHistory();
  const [initialContactEmail, setInitialContactEmail] = useState("");
  const [initialSectionName, setInitialSectionName] = useState("");
  const [initialDivisionName, setInitialDivisionName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [sectionName, setSectionName] = useState("");
  const [divisionName, setDivisionName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const [snackbar, setSnackbar] = useState(DEFAULT_SNACKBAR);

  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
    },
  }));

  const { id } = useParams();

  const classes = useStyles();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/divisioncontacts/${id}`, {
        headers: {
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      const resJson = await res.json();

      setInitialSectionName(resJson.sectionName);
      setInitialDivisionName(resJson.divisionName);
      setInitialContactEmail(resJson.contactEmail);
      setSectionName(resJson.sectionName);
      setDivisionName(resJson.divisionName);
      setContactEmail(resJson.contactEmail);
    } catch (e) {
      console.error(e);
      setError({type: "error", open: true, message: getError(e)});
    } finally {
      setIsLoading(false);
    }
  }

  const handleSubmit = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/divisioncontacts/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
        body: JSON.stringify({
          sectionName,
          divisionName,
          contactEmail,
        }),
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      setSnackbar({type: "success", open: true, message: "Success!"});
      setInitialContactEmail(contactEmail);
      setInitialSectionName(sectionName);
      setInitialDivisionName(divisionName);
      history.push("/divisioncontacts");
    } catch (e) {
      console.error(e);
      setSnackbar({type: "error", open: true, message: getError(e)});
    }
  }

  const handleDelete = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/divisioncontacts/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      history.push("/divisioncontacts");
    } catch (e) {
      console.error(e);
      setSnackbar({type: "error", open: true, message: getError(e)});
    }
  }

  const handleSendUpdateRequest = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/send-department-update-mailer?${
        new URLSearchParams({
          toEmail: contactEmail,
          sectionName: initialSectionName,
          divisionName: initialDivisionName,
        })
      }`, {
        headers: {
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      setSnackbar({type: "success", open: true, message: "Success!"});
    } catch (e) {
      console.error(e);
      setError({type: "error", open: true, message: getError(e)});
    }
  }

  const handleSnackbarClose = () => {
    setSnackbar(DEFAULT_SNACKBAR);
  }

  if (isLoading) {
    return (
      <CircularProgress />
    );
  }

  if (error) {
    return (
      <Alert severity={error.type}>
        {error.message}
      </Alert>
    );
  }

  return (<>
    <Prompt
      when={sectionName !== initialSectionName || divisionName !== initialDivisionName || contactEmail !== initialContactEmail}
      message="You have unsaved changes, are you sure you want to leave?"
    />

    <Snackbar open={snackbar.open} onClose={handleSnackbarClose} autoHideDuration={DEFAULT_SNACKBAR_TIME}>
      <Alert severity={snackbar.type}>
        {snackbar.message}
      </Alert>
    </Snackbar>

    <Box display="flex" justifyContent="space-between" alignItems="flex-start">
      <Typography variant="h3">
        Edit Division Contact
      </Typography>
      <Button variant="contained" color="primary" onClick={handleSendUpdateRequest}>Send update request via email</Button>
    </Box>

    <Paper className={classes.paper}>
      <Box mb={2}>
        <TextField fullWidth variant="outlined" id="sectionName" label="Section Name" value={sectionName} onChange={(e) => setSectionName(e.target.value)} />
      </Box>
      <Box mb={2}>
        <TextField fullWidth variant="outlined" id="divisionName" label="Division Name" value={divisionName} onChange={(e) => setDivisionName(e.target.value)} />
      </Box>
      <Box mb={2}>
        <TextField fullWidth variant="outlined" id="contactEmail" label="Contact Email" value={contactEmail} onChange={(e) => setContactEmail(e.target.value)} />
      </Box>
      <Box display="flex" justifyContent="space-between" pt={3}>
        <Button variant="contained" color="secondary" onClick={handleDelete}>Delete</Button>
        <Button variant="contained" color="primary" onClick={handleSubmit}>Update</Button>
      </Box>
    </Paper>
  </>);
}

export default DivisionContactView;
