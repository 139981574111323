import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Snackbar, TextField, Button, Typography, Box, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, InputLabel, Select, MenuItem } from "@material-ui/core";
import { Alert, Autocomplete } from "@material-ui/lab";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { useGoogleAuth } from "../../contexts/auth";
import { DEFAULT_SNACKBAR, DEFAULT_SNACKBAR_TIME, getError } from "../../utils/constants";
import { formatName } from "../../utils/string";
import { DraggableTableRow } from "../../components/DraggableTableRow";

function LegislativeLeadershipPositions() {
  const { googleUser } = useGoogleAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(undefined);
  const [people, setPeople] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [name, setName] = useState("");
  const [chamber, setChamber] = useState("Senate");
  const [leadershipPerson, setLeadershipPerson] = useState(undefined);
  const [snackbar, setSnackbar] = useState(DEFAULT_SNACKBAR);

  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/legislativeleadershippositions`, {
        headers: {
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      const res1 = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/legislativeprofiles`, {
        headers: {
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
      });

      if (!res1.ok) {
        throw new Error(await res1.text());
      }

      setData(await res.json());
      setPeople(await res1.json());
    } catch (e) {
      console.error(e);
      setError({type: "error", open: true, message: getError(e)});
    } finally {
      setIsLoading(false);
    }
  }

  const handleSubmit = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/legislativeleadershipposition`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
        body: JSON.stringify({ name, chamber, legid: leadershipPerson })
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      setSnackbar({type: "success", open: true, message: "Success!"});
      fetchData();
      setName("");
      setChamber("Senate");
      setLeadershipPerson(undefined);
    } catch (e) {
      console.error(e);
      setSnackbar({type: "error", open: true, message: getError(e)});
    }
  }

  const handleReorderSubmit = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/legislativeleadershippositionsorder`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
        body: JSON.stringify(data.map((d, i) => ({ id: d.LeadershipPositionID, displayOrder: i })))
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      setSnackbar({type: "success", open: true, message: "Success!"});
      fetchData();
    } catch (e) {
      console.error(e);
      setSnackbar({type: "error", open: true, message: getError(e)});
    }
  }

  const handleSnackbarClose = () => {
    setSnackbar(DEFAULT_SNACKBAR);
  }

  const moveRow = (dragIndex, hoverIndex) => {
    const draggedRow = data[dragIndex];
    const dataCopy = [...data];
    dataCopy.splice(dragIndex, 1);
    dataCopy.splice(hoverIndex, 0, draggedRow);
    setData(dataCopy);
  };

  if (isLoading) {
    return (
      <CircularProgress />
    );
  }

  if (error) {
    return (
      <Alert severity={error.type}>
        {error.message}
      </Alert>
    );
  }

  return (<>
    <Snackbar open={snackbar.open} onClose={handleSnackbarClose} autoHideDuration={DEFAULT_SNACKBAR_TIME}>
      <Alert severity={snackbar.type}>
        {snackbar.message}
      </Alert>
    </Snackbar>

    <Typography variant="h3">
      Create New Position
    </Typography>

    <Paper className={classes.paper}>
      <Box mb={2}>
        <InputLabel id="chamber">Chamber</InputLabel>
        <Select variant="outlined" fullWidth labelId="chamber" value={chamber} onChange={(e) => setChamber(e.target.value)}>
          <MenuItem value="Senate">Senate</MenuItem>
          <MenuItem value="House">House</MenuItem>
        </Select>
      </Box>
      <Box mb={2}>
        <TextField fullWidth variant="outlined" id="name" label="Name" value={name} onChange={(e) => setName(e.target.value)} />
      </Box>
      <Box>
        <Autocomplete options={people} getOptionLabel={(o) => formatName(o.FirstName, o.MiddleName, o.LastName)} renderInput={(params) => <TextField {...params} label="Legislator" variant="outlined" size="small" />} onChange={(_, o) => setLeadershipPerson(o.legid)} />
      </Box>
    </Paper>

    <Box mt={2} mb={2} display="flex" justifyContent="flex-end">
      <Button variant="contained" color="primary" onClick={() => handleSubmit()}>Add</Button>
    </Box>

    <Box display="flex">
      <Typography variant="h3">
        Sort Leadership Positions
      </Typography>
    </Box>

    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          <DndProvider backend={HTML5Backend}>
            {data.map((row, index) => (
              <DraggableTableRow index={index} moveRow={moveRow} hover={true} key={index}>
                <TableCell>
                  {row.LeadershipPositionChamber}
                </TableCell>
                <TableCell>
                  {row.LeadershipPositionName}
                </TableCell>
              </DraggableTableRow>
            ))}
          </DndProvider>
        </TableBody>
      </Table>
    </TableContainer>

    <Box mt={2} mb={2} display="flex" justifyContent="flex-end">
      <Button variant="contained" color="primary" onClick={() => handleReorderSubmit()}>Save Changes</Button>
    </Box>
  </>);
}

export default LegislativeLeadershipPositions;
