import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Button } from "@material-ui/core";

import { useGoogleAuth } from "../../contexts/auth";

function Login() {
  const { isSignedIn, grantOfflineAccess } = useGoogleAuth();
  const history = useHistory();

  useEffect(() => {
    if (isSignedIn) {
      return history.push("/homepageentries");
    }
  }, [isSignedIn]);

  return (
    <Button variant="contained" color="primary" onClick={grantOfflineAccess}>
      Sign in
    </Button>
  );
}

export default Login;
