import React, { useState } from "react";

import { Button, Box, Snackbar, Typography, InputLabel, Select, MenuItem } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { useGoogleAuth } from "../../contexts/auth";
import { DEFAULT_SNACKBAR, DEFAULT_SNACKBAR_TIME, getError } from "../../utils/constants";

function DistrictCSVUpload() {
  const { googleUser } = useGoogleAuth();
  const [file, setFile] = useState(undefined);
  const [chamber, setChamber] = useState("Senate");
  const [snackbar, setSnackbar] = useState(DEFAULT_SNACKBAR);

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append(
        "districtCSV",
        file,
        file.name
      );

      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/district-csv-upload/${chamber}`, {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
        body: formData
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      setSnackbar({type: "success", open: true, message: "Success!"});
    } catch (e) {
      console.error(e);
      setSnackbar({type: "error", open: true, message: getError(e)});
    }
  }

  const onFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSnackbarClose = () => {
    setSnackbar(DEFAULT_SNACKBAR);
  }

  return (<>
    <Snackbar open={snackbar.open} onClose={handleSnackbarClose} autoHideDuration={DEFAULT_SNACKBAR_TIME}>
      <Alert severity={snackbar.type}>
        {snackbar.message}
      </Alert>
    </Snackbar>

    <Typography variant="h3">
      Upload District CSVs
    </Typography>

    <Box mt={1}>
      <Typography>
        Export the Google Sheet by going to File &gt; Download &gt; Comma Separated Values (csv)
      </Typography>
    </Box>

    <Box mt={1}>
      <InputLabel id="chamber">Chamber</InputLabel>
      <Select variant="outlined" fullWidth labelId="chamber" value={chamber} onChange={(e) => setChamber(e.target.value)}>
        <MenuItem value="Senate">Senate</MenuItem>
        <MenuItem value="House">House</MenuItem>
      </Select>
    </Box>

    <Box mt={1}>
      <InputLabel>Upload file</InputLabel>
      <input type="file" onChange={onFileChange} />
    </Box>

    <Box mt={2}>
      <Button variant="contained" color="primary" onClick={handleSubmit}>Send</Button>
    </Box>
  </>);
}

export default DistrictCSVUpload;
