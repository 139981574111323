import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Grid, Typography, Snackbar, CircularProgress, Paper } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { useGoogleAuth } from "../../contexts/auth";
import { DEFAULT_SNACKBAR, DEFAULT_SNACKBAR_TIME, getError } from "../../utils/constants";
import Wysiwyg from "../../components/Wysiwyg";

function HomePageFreetext() {
  const { googleUser } = useGoogleAuth();
  const [primaryContentValue, setPrimaryContentValue] = useState("");
  const [secondaryContentValue, setSecondaryContentValue] = useState("");
  const [movesValue, setMovesValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const [snackbar, setSnackbar] = useState(DEFAULT_SNACKBAR);

  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/homepagefreetext`, {
        headers: {
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      const resJson = await res.json();

      setPrimaryContentValue(resJson.primary_content);
      setSecondaryContentValue(resJson.secondary_content);
      setMovesValue(resJson.moves);
    } catch (e) {
      console.error(e);
      setError({type: "error", open: true, message: getError(e)});
    } finally {
      setIsLoading(false);
    }
  }

  const handleSubmit = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/homepagefreetext`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
        body: JSON.stringify({
          primary_content: primaryContentValue,
          secondary_content: secondaryContentValue,
          moves: movesValue,
        }),
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      setSnackbar({type: "success", open: true, message: "Success!"});
      fetchData();
    } catch (e) {
      console.error(e);
      setSnackbar({type: "error", open: true, message: getError(e)});
    }
  }

  const handleSnackbarClose = () => {
    setSnackbar(DEFAULT_SNACKBAR);
  }

  if (isLoading) {
    return (
      <CircularProgress />
    );
  }

  if (error) {
    return (
      <Alert severity={error.type}>
        {error.message}
      </Alert>
    );
  }

  return (<>
    <Snackbar open={snackbar.open} onClose={handleSnackbarClose} autoHideDuration={DEFAULT_SNACKBAR_TIME}>
      <Alert severity={snackbar.type}>
        {snackbar.message}
      </Alert>
    </Snackbar>

    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Typography variant="h3">
            Primary Content
          </Typography>
          <Wysiwyg value={primaryContentValue} onChange={setPrimaryContentValue} />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Typography variant="h3">
            Secondary Content
          </Typography>
          <Wysiwyg value={secondaryContentValue} onChange={setSecondaryContentValue} />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Typography variant="h3">
            Moves
          </Typography>
          <Wysiwyg value={movesValue} onChange={setMovesValue} />
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Box display="flex" justifyContent="flex-end">
          <Button variant="contained" color="primary" onClick={handleSubmit}>Save</Button>
        </Box>
      </Grid>
    </Grid>
  </>);
}

export default HomePageFreetext;
