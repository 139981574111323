export const formatName = (firstName, middleName, lastName, title) => {
  const lastNameFirstName = [];
  if (!isNull(lastName)) {
    lastNameFirstName.push(lastName);
  }
  if (!isNull(firstName)) {
    lastNameFirstName.push(firstName);
  }

  return `${lastNameFirstName.join(", ")} ${!isNull(middleName) ? middleName : ""} ${!isNull(title) ? " - " + title: ""}`;
}

export const isNull = (str) => {
  return str === null || str === undefined || str.toString().toUpperCase() === "NULL" || str === "";
}
