import { Box, Button } from "@material-ui/core";

import ReactQuill, { Quill } from "react-quill";
import htmlEditButton from "quill-html-edit-button";
import ImageResize  from "quill-image-resize";

function Wysiwyg(props) {
  const { value, onChange, onSubmit, buttonCopy } = props;

  Quill.register({
    "modules/htmlEditButton": htmlEditButton,
    "modules/imageResize": ImageResize
  });

  const Font = Quill.import("formats/font");
  Font.whitelist = ["Roboto", "Arial", "Verdana", "Helvetica", "Tahoma", "trebuchet-ms", "times-new-roman", "Georgia", "Garamond", "courier-new", "brush-script-mt"];
  Quill.register(Font, true);

  const modules = {
    toolbar: [
      [{ "header": [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ "font": Font.whitelist }],
      [{"color": []}], [{ "align": [] }],
      [{"list": "ordered"}, {"list": "bullet"}, {"indent": "-1"}, {"indent": "+1"}],
      ["link", "image"],
    ],
    "htmlEditButton": {},
    "imageResize": {},
    "clipboard": {
      "matchVisual": false,
    },
  };

  const formats = ["header", "bold", "italic", "underline", "strike", "blockquote", "font", "list", "bullet", "indent", "link", "image", "color", "align", "height", "width"];

  return (<>
    <ReactQuill
      value={value}
      onChange={onChange}
      modules={modules}
      formats={formats}
    />
    {onSubmit && buttonCopy && (
      <Box display="flex" justifyContent="flex-end" pt={3}>
        <Button variant="contained" color="primary" onClick={onSubmit}>{buttonCopy}</Button>
      </Box>
    )}
  </>);
}

export default Wysiwyg;
