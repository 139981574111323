import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { Button, TextField, Box, Typography, Snackbar, CircularProgress, Paper, TableHead, Table, TableBody, TableCell, TableContainer, TableFooter, TablePagination, TableRow } from "@material-ui/core";
import { Alert, Autocomplete } from "@material-ui/lab";

import { useGoogleAuth } from "../../contexts/auth";
import { DEFAULT_SNACKBAR, DEFAULT_SNACKBAR_TIME, getError } from "../../utils/constants";

const rowsPerPage = 10;

function DivisionContacts() {
  const { googleUser } = useGoogleAuth();
  const history = useHistory();
  const [contactEmail, setContactEmail] = useState("");
  const [sectionName, setSectionName] = useState("");
  const [divisionName, setDivisionName] = useState("");
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [snackbar, setSnackbar] = useState(DEFAULT_SNACKBAR);

  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/divisioncontacts`, {
        headers: {
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      setData(await res.json());
    } catch (e) {
      console.error(e);
      setError({type: "error", open: true, message: getError(e)});
    } finally {
      setIsLoading(false);
    }
  }

  const handleSubmit = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/divisioncontacts`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
        body: JSON.stringify({
          sectionName,
          divisionName,
          contactEmail,
        }),
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      setSnackbar({type: "success", open: true, message: "Success!"});
      fetchData();
      setDivisionName("");
      setSectionName("");
      setContactEmail("");
    } catch (e) {
      console.error(e);
      setSnackbar({type: "error", open: true, message: getError(e)});
    }
  }

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleSnackbarClose = () => {
    setSnackbar(DEFAULT_SNACKBAR);
  }

  if (isLoading) {
    return (
      <CircularProgress />
    );
  }

  if (error) {
    return (
      <Alert severity={error.type}>
        {error.message}
      </Alert>
    );
  }

  return (<>
    <Snackbar open={snackbar.open} onClose={handleSnackbarClose} autoHideDuration={DEFAULT_SNACKBAR_TIME}>
      <Alert severity={snackbar.type}>
        {snackbar.message}
      </Alert>
    </Snackbar>

    <Typography variant="h3">
      Add New Division Contact
    </Typography>

    <Paper className={classes.paper}>
      <Box mb={2}>
        <TextField fullWidth variant="outlined" id="sectionName" label="Section Name" value={sectionName} onChange={(e) => setSectionName(e.target.value)} />
      </Box>
      <Box mb={2}>
        <TextField fullWidth variant="outlined" id="divisionName" label="Division Name" value={divisionName} onChange={(e) => setDivisionName(e.target.value)} />
      </Box>
      <Box mb={2}>
        <TextField fullWidth variant="outlined" id="contactEmail" label="Contact Email" value={contactEmail} onChange={(e) => setContactEmail(e.target.value)} />
      </Box>
      <Box display="flex" justifyContent="flex-end" pt={3}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>Add</Button>
      </Box>
    </Paper>

    <Box display="flex" justifyContent="space-between" pt={3}>
      <Typography variant="h3">
        Division Contacts
      </Typography>

      <Box width="30%">
        <Autocomplete options={data} getOptionLabel={(o) => `${o.sectionName} - ${o.divisionName} (${o.contactEmail})`} renderInput={(params) => <TextField {...params} label="Search" variant="outlined" size="small" />} onChange={(_, o) => history.push(`/divisioncontact/${encodeURIComponent(o.sectionName)}+${encodeURIComponent(o.divisionName)}`)} />
      </Box>
    </Box>

    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Section Name</TableCell>
            <TableCell>Division Name</TableCell>
            <TableCell>Contact Email</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
            <TableRow hover={true} component={Link} to={`/divisioncontact/${encodeURIComponent(row.sectionName)}+${encodeURIComponent(row.divisionName)}`} key={index}>
              <TableCell>
                {row.sectionName}
              </TableCell>
              <TableCell>
                {row.divisionName}
              </TableCell>
              <TableCell>
                {row.contactEmail}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              count={data.length}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[rowsPerPage]}
              page={page}
              onPageChange={handleChangePage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  </>);
}

export default DivisionContacts;
