import React, { useState } from "react";

import { Box, Button, Typography, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { useGoogleAuth } from "../../contexts/auth";
import { DEFAULT_SNACKBAR, DEFAULT_SNACKBAR_TIME, getError } from "../../utils/constants";

function SendBulkMailer() {
  const { googleUser } = useGoogleAuth();
  const [snackbar, setSnackbar] = useState(DEFAULT_SNACKBAR);

  const handleSubmit = async (type) => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/send-bulk-update-mailers/${type}`, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      setSnackbar({type: "success", open: true, message: "Success!"});
    } catch (e) {
      console.error(e);
      setSnackbar({type: "error", open: true, message: getError(e)});
    }
  }

  const handleSnackbarClose = () => {
    setSnackbar(DEFAULT_SNACKBAR);
  }

  return (<>
    <Snackbar open={snackbar.open} onClose={handleSnackbarClose} autoHideDuration={DEFAULT_SNACKBAR_TIME}>
      <Alert severity={snackbar.type}>
        {snackbar.message}
      </Alert>
    </Snackbar>

    <Typography variant="h3">
      Send Bulk Mailer
    </Typography>

    <Box mt={1}>
      <Typography>
        Note: it may take several hours for all emails to be sent out.
      </Typography>
    </Box>

    <Box mt={1}>
      <Button variant="contained" color="primary" onClick={() => handleSubmit('legislative')}>Send to all legislators</Button>
    </Box>
    <Box mt={2}>
      <Button variant="contained" color="primary" onClick={() => handleSubmit('executive')}>Send to all executives</Button>
    </Box>
  </>);
}

export default SendBulkMailer;
