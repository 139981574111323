import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import { Button, Box, CircularProgress, Paper, TableHead, Table, TableBody, TableCell, TableContainer, TableFooter, TablePagination, TableRow, TextField } from "@material-ui/core";
import { Alert, Autocomplete } from "@material-ui/lab";

import { useGoogleAuth } from "../../contexts/auth";
import { getError } from "../../utils/constants";
import { formatName } from "../../utils/string";

const rowsPerPage = 10;

function ExecutiveProfiles() {
  const { googleUser } = useGoogleAuth();
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(undefined);
  const [error, setError] = useState(undefined);
  const history = useHistory();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/executiveprofiles`, {
        headers: {
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      setData((await res.json()));
    } catch (e) {
      console.error(e);
      setError({type: "error", open: true, message: getError(e)});
    } finally {
      setIsLoading(false);
    }
  }

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  if (isLoading) {
    return (
      <CircularProgress />
    );
  }

  if (error) {
    return (
      <Alert severity={error.type}>
        {error.message}
      </Alert>
    );
  }

  return (<>
    <Box display="flex" justifyContent="space-between" mb={2}>
      <Button variant="contained" color="primary" to="/executiveprofile" component={Link}>Create New Profile</Button>
      <Box width="30%">
        <Autocomplete options={data} getOptionLabel={(o) => `${formatName(o.FirstName, o.MiddleName, o.LastName)} (${o.RNUV})`} renderInput={(params) => <TextField {...params} label="Search" variant="outlined" size="small" />} onChange={(_, o) => history.push(`/executiveprofile/${o.execid}`)} />
      </Box>
    </Box>

    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>RNUV</TableCell>
            <TableCell>Section</TableCell>
            <TableCell>Division</TableCell>
            <TableCell>Name</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
            <TableRow hover={true} component={Link} to={`/executiveprofile/${row.execid}`} key={index}>
              <TableCell>
                {row.RNUV}
              </TableCell>
              <TableCell>
                {row.TheSection}
              </TableCell>
              <TableCell>
                {row.Division}
              </TableCell>
              <TableCell>
                {formatName(row.FirstName, row.MiddleName, row.LastName)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              count={data.length}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[rowsPerPage]}
              page={page}
              onPageChange={handleChangePage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  </>);
}

export default ExecutiveProfiles;
