import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { Button, TextField, Box, Typography, Snackbar, CircularProgress, Paper, Table, TableBody, TableHead, TableCell, TableContainer, TableFooter, TablePagination, TableRow } from "@material-ui/core";
import { Alert, Autocomplete } from "@material-ui/lab";

import { useGoogleAuth } from "../../contexts/auth";
import { formatName } from "../../utils/string";
import { DEFAULT_SNACKBAR, DEFAULT_SNACKBAR_TIME, getError } from "../../utils/constants";

const rowsPerPage = 10;

function CdAlternateContacts() {
  const { googleUser } = useGoogleAuth();
  const history = useHistory();
  const [altContactEmail, setAltContactEmail] = useState("");
  const [newPerson, setNewPerson] = useState(undefined);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [snackbar, setSnackbar] = useState(DEFAULT_SNACKBAR);
  const [allPeople, setAllPeople] = useState(undefined);

  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/cdalternatecontacts`, {
        headers: {
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      const res1 = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/congressionaldelegationprofiles`, {
        headers: {
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
      });

      if (!res1.ok) {
        throw new Error(await res1.text());
      }
      setAllPeople((await res1.json()));

      setData(await res.json());
    } catch (e) {
      console.error(e);
      setError({type: "error", open: true, message: getError(e)});
    } finally {
      setIsLoading(false);
    }
  }

  const handleSubmit = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/cdalternatecontacts`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
        body: JSON.stringify({
          altContactEmail,
          primaryID: newPerson,
        }),
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      setSnackbar({type: "success", open: true, message: "Success!"});
      fetchData();
      setAltContactEmail("");
      setNewPerson(undefined);
    } catch (e) {
      console.error(e);
      setSnackbar({type: "error", open: true, message: getError(e)});
    }
  }

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleSnackbarClose = () => {
    setSnackbar(DEFAULT_SNACKBAR);
  }

  if (isLoading) {
    return (
      <CircularProgress />
    );
  }

  if (error) {
    return (
      <Alert severity={error.type}>
        {error.message}
      </Alert>
    );
  }

  return (<>
    <Snackbar open={snackbar.open} onClose={handleSnackbarClose} autoHideDuration={DEFAULT_SNACKBAR_TIME}>
      <Alert severity={snackbar.type}>
        {snackbar.message}
      </Alert>
    </Snackbar>

    <Typography variant="h3">
      Add New Alternate Contact
    </Typography>

    <Paper className={classes.paper}>
      <Autocomplete options={allPeople} getOptionLabel={(o) => formatName(o.fname, o.mname, o.lname)} renderInput={(params) => <TextField {...params} label="Search for legislator" variant="outlined" />} onChange={(_, o) => setNewPerson(o !== null ? o.CongressID : undefined)} />
      <Box mt={2}>
        <TextField fullWidth variant="outlined" id="altContactEmail" label="Contact Email" value={altContactEmail} onChange={(e) => setAltContactEmail(e.target.value)} />
      </Box>
      <Box display="flex" justifyContent="flex-end" pt={3}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>Add</Button>
      </Box>
    </Paper>

    <Box display="flex" justifyContent="space-between" pt={3}>
      <Typography variant="h3">
        Alternate Contacts
      </Typography>

      <Box width="30%">
        <Autocomplete options={data} getOptionLabel={(o) => `${formatName(o.FirstName, o.MiddleName, o.LastName)} (${o.altContactEmail})`} renderInput={(params) => <TextField {...params} label="Search" variant="outlined" size="small" />} onChange={(_, o) => history.push(`/cdalternatecontact/${o.primaryID}`)} />
      </Box>
    </Box>

    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Legislator Name</TableCell>
            <TableCell>Contact Email</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
            <TableRow hover={true} component={Link} to={`/cdalternatecontact/${row.primaryID}`} key={index}>
              <TableCell>
                {formatName(row.FirstName, row.MiddleName, row.LastName)}
              </TableCell>
              <TableCell>
                {row.altContactEmail}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              count={data.length}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[rowsPerPage]}
              page={page}
              onPageChange={handleChangePage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  </>);
}

export default CdAlternateContacts;
