import React from "react";

import { Dialog, DialogContent, IconButton, Box, DialogTitle } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";

function Modal(props) {
  const { open, onClose, children } = props;

  return (
    <Dialog maxWidth="md" fullWidth onClose={onClose} open={open}>
      <DialogTitle>
        <Box display="flex" justifyContent="flex-end">
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box pb={3}>
          {children}
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default Modal;
