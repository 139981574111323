import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { CircularProgress, Paper, TableHead, Table, TableBody, TableCell, TableContainer, TableFooter, TablePagination, TableRow } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { useGoogleAuth } from "../../contexts/auth";
import { getError } from "../../utils/constants";
import { formatName } from "../../utils/string";

const rowsPerPage = 10;

function PendingUpdates() {
  const { googleUser } = useGoogleAuth();
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(undefined);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/pending-update-mailer-requests`, {
        headers: {
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      setData(await res.json());
    } catch (e) {
      console.error(e);
      setError({type: "error", open: true, message: getError(e)});
    } finally {
      setIsLoading(false);
    }
  }

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  if (isLoading) {
    return (
      <CircularProgress />
    );
  }

  if (error) {
    return (
      <Alert severity={error.type}>
        {error.message}
      </Alert>
    );
  }

  return (<>
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Submitted</TableCell>
            <TableCell>Person</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
            <TableRow hover={true} component={Link} to={`/pendingupdate/${row.type}/${row.id}`} key={index}>
              <TableCell>
                {row.created_at}
              </TableCell>
              <TableCell>
                {formatName(row.FirstName, row.MiddleName, row.LastName)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              count={data.length}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[rowsPerPage]}
              page={page}
              onPageChange={handleChangePage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  </>);
}

export default PendingUpdates;
