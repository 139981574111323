import React, { useState, useEffect } from "react";
import { Prompt, useParams, useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography, TextField, Box, Snackbar, CircularProgress, Paper } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { useGoogleAuth } from "../../contexts/auth";
import { DEFAULT_SNACKBAR, DEFAULT_SNACKBAR_TIME, getError } from "../../utils/constants";
import Wysiwyg from "../../components/Wysiwyg";

function HomePageEntryView() {
  const { googleUser } = useGoogleAuth();
  const history = useHistory();
  const [initialValue, setInitialValue] = useState("");
  const [initialTitle, setInitialTitle] = useState("");
  const [value, setValue] = useState("");
  const [title, setTitle] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const [snackbar, setSnackbar] = useState(DEFAULT_SNACKBAR);

  const { id } = useParams();

  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/home/${id}`, {
        headers: {
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      const resJson = await res.json();

      setInitialTitle(resJson.Title);
      setInitialValue(resJson.Entry);
      setTitle(resJson.Title);
      setValue(resJson.Entry);
    } catch (e) {
      console.error(e);
      setError({type: "error", open: true, message: getError(e)});
    } finally {
      setIsLoading(false);
    }
  }

  const handleSubmit = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/home/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
        body: JSON.stringify({
          Title: title,
          Entry: value,
        }),
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      setSnackbar({type: "success", open: true, message: "Success!"});
      fetchData();
    } catch (e) {
      console.error(e);
      setSnackbar({type: "error", open: true, message: getError(e)});
    }
  }

  const handleDelete = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/home/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${googleUser.tokenId}`,
        }
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      history.push("/homepageentries");
    } catch (e) {
      console.error(e);
      setSnackbar({type: "error", open: true, message: getError(e)});
    }
  }

  const handleSnackbarClose = () => {
    setSnackbar(DEFAULT_SNACKBAR);
  }

  if (isLoading) {
    return (
      <CircularProgress />
    );
  }

  if (error) {
    return (
      <Alert severity={error.type}>
        {error.message}
      </Alert>
    );
  }

  return (<>
    <Prompt
      when={title !== initialTitle || value !== initialValue}
      message="You have unsaved changes, are you sure you want to leave?"
    />

    <Snackbar open={snackbar.open} onClose={handleSnackbarClose} autoHideDuration={DEFAULT_SNACKBAR_TIME}>
      <Alert severity={snackbar.type}>
        {snackbar.message}
      </Alert>
    </Snackbar>

    <Typography variant="h3">
      Edit Entry
    </Typography>

    <Paper className={classes.paper}>
      <Box mb={2}>
        <TextField fullWidth variant="outlined" id="title" label="Title" value={title} onChange={(e) => setTitle(e.target.value)} />
      </Box>
      <Wysiwyg value={value} onChange={setValue} onSubmit={handleSubmit} buttonCopy="Update" />
      <Box mt={2} display="flex" justifyContent="flex-end">
        <Button variant="contained" color="secondary" onClick={handleDelete}>Delete Entry</Button>
      </Box>
    </Paper>
  </>);
}

export default HomePageEntryView;
