import React, { useEffect } from "react";
import { Route, Redirect, useLocation } from "react-router";

import { useGoogleAuth } from "../contexts/auth";

function ProtectedRoute({ children, ...rest }) {
  const { isSignedIn, googleUser, refreshUser } = useGoogleAuth();
  const location = useLocation();

  useEffect(() => {
    if (Date.now() >= googleUser.expiresAt) {
      refreshToken();
    }
  }, [location]);

  const refreshToken = async () => {
    await refreshUser();
    window.location.reload();
  }

  return (
    <Route {...rest} render={() => {
      return isSignedIn
        ? children
        : <Redirect to="/" />
    }} />
  );
}

export default ProtectedRoute;
