import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { Snackbar, TextField, Button, Typography, Box, CircularProgress, Paper, TableHead, Table, TableBody, TableCell, TableContainer, TableRow } from "@material-ui/core";
import { Alert, Autocomplete } from "@material-ui/lab";

import { useGoogleAuth } from "../../contexts/auth";
import Modal from "../../components/Modal";
import { DEFAULT_SNACKBAR, DEFAULT_SNACKBAR_TIME, getError } from "../../utils/constants";
import { formatName } from "../../utils/string";

function LegislativeLeadership() {
  const { googleUser } = useGoogleAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(undefined);
  const [people, setPeople] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [open, setOpen] = useState(false);
  const [editingPerson, setEditingPerson] = useState(undefined);
  const [snackbar, setSnackbar] = useState(DEFAULT_SNACKBAR);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/legislativeleadership`, {
        headers: {
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      const res1 = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/legislativeprofiles`, {
        headers: {
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
      });

      if (!res1.ok) {
        throw new Error(await res1.text());
      }

      setData(await res.json());
      setPeople(await res1.json());
    } catch (e) {
      console.error(e);
      setError({type: "error", open: true, message: getError(e)});
    } finally {
      setIsLoading(false);
    }
  }

  const saveEditingPerson = async (legid) => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/legislativeleadership/${editingPerson.posid}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
        body: JSON.stringify({ legid })
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      setSnackbar({type: "success", open: true, message: "Success!"});
      fetchData();
    } catch (e) {
      console.error(e);
      setSnackbar({type: "error", open: true, message: getError(e)});
    }
  }

  const handleModalClose = () => {
    setOpen(false);
    setEditingPerson(undefined);
  }

  const renderModal = () => {
    if (open) {
      return (
        <Modal open={open} onClose={handleModalClose}>
          <Typography align="center" variant="h5">
            Editing {editingPerson.title} ({editingPerson.chamber})
          </Typography>

          <Typography>
            Current: {formatName(editingPerson.FirstName, editingPerson.MiddleName, editingPerson.LastName)}
          </Typography>

          <Box mt={2} mb={2}>
            <Autocomplete options={people} getOptionLabel={(o) => formatName(o.FirstName, o.MiddleName, o.LastName)} renderInput={(params) => <TextField {...params} label="Search" variant="outlined" size="small" />} onChange={(_, o) => saveEditingPerson(o.legid)} />
          </Box>
        </Modal>
      )
    }
  }

  const handleSnackbarClose = () => {
    setSnackbar(DEFAULT_SNACKBAR);
  }

  if (isLoading) {
    return (
      <CircularProgress />
    );
  }

  if (error) {
    return (
      <Alert severity={error.type}>
        {error.message}
      </Alert>
    );
  }

  return (<>
    {renderModal()}
    <Snackbar open={snackbar.open} onClose={handleSnackbarClose} autoHideDuration={DEFAULT_SNACKBAR_TIME}>
      <Alert severity={snackbar.type}>
        {snackbar.message}
      </Alert>
    </Snackbar>

    <Box display="flex" justifyContent="flex-end" mb={2}>
      <Button component={Link} to="/legislativeleadershippositions" variant="contained" color="primary">Update legislative positions</Button>
    </Box>

    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Chamber</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Current</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {data.map((row, index) => (
            <TableRow hover={true} onClick={() => { setOpen(true); setEditingPerson(row)}} key={index}>
              <TableCell>
                {row.chamber}
              </TableCell>
              <TableCell>
                {row.title}
              </TableCell>
              <TableCell>
                {formatName(row.FirstName, row.MiddleName, row.LastName)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </>);
}

export default LegislativeLeadership;
