import React from "react";

import { Alert } from "@material-ui/lab";

function Success() {
  return (
    <Alert severity="success">
      Thank you for submitting updates.
    </Alert>
  );
}

export default Success;
