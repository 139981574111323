import React, { useState, useEffect } from "react";
import { Prompt, Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { TextField, Box, Typography, Snackbar, CircularProgress, Paper, TableHead, Table, TableBody, TableCell, TableContainer, TableFooter, TablePagination, TableRow } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { useGoogleAuth } from "../../contexts/auth";
import { DEFAULT_SNACKBAR, DEFAULT_SNACKBAR_TIME, getError } from "../../utils/constants";
import Wysiwyg from "../../components/Wysiwyg";

const rowsPerPage = 10;

function HomePageEntries() {
  const { googleUser } = useGoogleAuth();
  const [value, setValue] = useState("");
  const [title, setTitle] = useState("");
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [snackbar, setSnackbar] = useState(DEFAULT_SNACKBAR);

  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/home`, {
        headers: {
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      setData(await res.json());
    } catch (e) {
      console.error(e);
      setError({type: "error", open: true, message: getError(e)});
    } finally {
      setIsLoading(false);
    }
  }

  const handleSubmit = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/home`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
        body: JSON.stringify({
          Title: title,
          Entry: value,
        }),
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      setSnackbar({type: "success", open: true, message: "Success!"});
      fetchData();
      setTitle("")
      setValue("");
    } catch (e) {
      console.error(e);
      setSnackbar({type: "error", open: true, message: getError(e)});
    }
  }

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleSnackbarClose = () => {
    setSnackbar(DEFAULT_SNACKBAR);
  }

  if (isLoading) {
    return (
      <CircularProgress />
    );
  }

  if (error) {
    return (
      <Alert severity={error.type}>
        {error.message}
      </Alert>
    );
  }

  return (<>
    <Prompt
      when={title !== "" || value !== ""}
      message="You have unsaved changes, are you sure you want to leave?"
    />

    <Snackbar open={snackbar.open} onClose={handleSnackbarClose} autoHideDuration={DEFAULT_SNACKBAR_TIME}>
      <Alert severity={snackbar.type}>
        {snackbar.message}
      </Alert>
    </Snackbar>

    <Typography variant="h3">
      Create New Entry
    </Typography>

    <Paper className={classes.paper}>
      <Box mb={2}>
        <TextField fullWidth variant="outlined" id="title" label="Title" value={title} onChange={(e) => setTitle(e.target.value)} />
      </Box>
      <Wysiwyg value={value} onChange={setValue} onSubmit={handleSubmit} buttonCopy="Post" />
    </Paper>

    <Box pt={3}>
      <Typography variant="h3">
        Old Entries
      </Typography>
    </Box>

    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Title</TableCell>
            <TableCell>Entry</TableCell>
            <TableCell>Timestamp</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
            <TableRow hover={true} component={Link} to={`/homepageentry/${row.ID}`} key={index}>
              <TableCell>
                {row.Title}
              </TableCell>
              <TableCell dangerouslySetInnerHTML={{ __html: row.Entry}} />
              <TableCell>
                {row.TimeStamp}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              count={data.length}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[rowsPerPage]}
              page={page}
              onPageChange={handleChangePage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  </>);
}

export default HomePageEntries;
