import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, Typography, Box, Snackbar, CircularProgress, Paper, InputLabel } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { useGoogleAuth } from "../../contexts/auth";
import { DEFAULT_SNACKBAR, DEFAULT_SNACKBAR_TIME, getError } from "../../utils/constants";
import { formatName } from "../../utils/string";

function PendingUpdateView() {
  const { googleUser } = useGoogleAuth();
  const history = useHistory();
  const { type, id } = useParams();

  const [data, setData] = useState({ original: {}, new: {} });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const [snackbar, setSnackbar] = useState(DEFAULT_SNACKBAR);

  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    fetchData();
  }, [id, type]);

  const fetchData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/pending-${type}-update/${id}`, {
        headers: {
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      setData(await res.json());
    } catch (e) {
      console.error(e);
      setError({type: "error", open: true, message: getError(e)});
    } finally {
      setIsLoading(false);
    }
  }

  const handleSubmit = async (statusChangedTo) => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/${type}-update-status/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
        body: JSON.stringify({ status: statusChangedTo }),
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      history.push('/pendingupdates');
    } catch (e) {
      console.error(e);
      setSnackbar({type: "error", open: true, message: getError(e)});
    }
  }

  const handleSnackbarClose = () => {
    setSnackbar(DEFAULT_SNACKBAR);
  }

  if (isLoading) {
    return (
      <CircularProgress />
    );
  }

  if (error) {
    return (
      <Alert severity={error.type}>
        {error.message}
      </Alert>
    );
  }

  return (<>
    <Snackbar open={snackbar.open} onClose={handleSnackbarClose} autoHideDuration={DEFAULT_SNACKBAR_TIME}>
      <Alert severity={snackbar.type}>
        {snackbar.message}
      </Alert>
    </Snackbar>

    <Box display="flex" justifyContent="space-between">
      <Typography variant="h3">
        Updates:
        {(type === "legislative" || type === "executive") && formatName(data.original.FirstName, data.original.MiddleName, data.original.LastName)}
        {type === "cd" && formatName(data.original.fname, data.original.mname, data.original.lname)}
      </Typography>
    </Box>

    <Grid container spacing={3}>
      <Grid item md={6}>
        <Typography variant="h6">Original</Typography>
        <Paper className={classes.paper}>
          {Object.keys(data.new).filter(f => !["id", "status", "created_at"].includes(f)).map((field, index) => (
            <Box key={index} mb={2}>
              <InputLabel>{field}</InputLabel>
              <Typography color={data.original[field] !== data.new[field] ? "secondary" : "textPrimary"}>
                {data.original[field]}
              </Typography>
            </Box>
          ))}
        </Paper>
      </Grid>

      <Grid item md={6}>
        <Typography variant="h6">Updated</Typography>
        <Paper className={classes.paper}>
          {Object.keys(data.new).filter(f => !["id", "status", "created_at"].includes(f)).map((field, index) => (
            <Box key={index} mb={2}>
              <InputLabel>{field}</InputLabel>
              <Typography color={data.original[field] !== data.new[field] ? "primary" : "textPrimary"}>
                {data.new[field]}
              </Typography>
            </Box>
          ))}
        </Paper>
      </Grid>
    </Grid>

    <Box mt={2} mb={2} display="flex" justifyContent="space-between">
      <Button variant="contained" color="secondary" onClick={() => handleSubmit('rejected')}>Reject</Button>
      <Button variant="contained" color="primary" onClick={() => handleSubmit('approved')}>Approve</Button>
    </Box>
  </>);
}

export default PendingUpdateView;
