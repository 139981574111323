import React, { useState, useEffect } from "react";
import { Prompt, useParams, useLocation, useHistory } from "react-router-dom";
import * as ReactS3Uploader from "react-s3-uploader";

import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography, TextField, Box, Snackbar, CircularProgress, Paper, InputLabel } from "@material-ui/core";
import { Alert, Autocomplete } from "@material-ui/lab";

import { useGoogleAuth } from "../../contexts/auth";
import { DEFAULT_SNACKBAR, DEFAULT_SNACKBAR_TIME, getError, defaultData } from "../../utils/constants";
import { formatName } from "../../utils/string";

function ExecutiveProfileView() {
  const { googleUser } = useGoogleAuth();
  const history = useHistory();
  const { id } = useParams();
  const fields = ["RNUV", "BasicOrDetails", "TheSection", "Division", "PocketDirectory", "AgencyAbbreviation", "Title", "FirstName", "MiddleName", "LastName", "Phone", "Email", "Twitter", "StreetAddress", "City", "State", "Zip", "Fax", "Staff", "ReportsTo", "DateofBirth", "PlaceFrom", "PlaceOfBirth", "Education", "MaritalStatus", "Children", "PreviousEmployment", "VeteranService", "StartDate", "YearsinStateService", "Retired", "JobDescription", "Priorities", "Organizations", "pix", "Website", "YearElected", "NumberofEmployees", "PartyAffiliation", "Lockups", "nprimary", "ngeneral", "BeginningCash", "TotalReceipts", "TotalDisbursements", "CashonHand"];

  const [initialData, setInitialData] = useState(defaultData(fields));
  const [data, setData] = useState(defaultData(fields));
  const [people, setPeople] = useState([]);
  const [isLoading, setIsLoading] = useState(id ? true : false);
  const [error, setError] = useState(undefined);
  const [snackbar, setSnackbar] = useState(DEFAULT_SNACKBAR);
  const location = useLocation();

  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (id) {
      fetchData();
    } else {
      fetchPeople();
    }

    const TheSection = query.get("parent_id");
    const Division = query.get("id");
    if (TheSection || Division) {
      setData(d => ({...d, TheSection, Division}));
    }
  }, [id, location.search]);

  const fetchData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/executiveprofiles/${id}`, {
        headers: {
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      const resJson = await res.json();
      setInitialData(resJson);
      setData(resJson);
    } catch (e) {
      console.error(e);
      setError({type: "error", open: true, message: getError(e)});
    } finally {
      setIsLoading(false);
    }
  }

  const fetchPeople = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/executiveprofiles`, {
        headers: {
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      setPeople(await res.json());
    } catch (e) {
      console.error(e);
      setError({type: "error", open: true, message: getError(e)});
    } finally {
      setIsLoading(false);
    }
  }

  const handleSubmit = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/executiveprofiles${id ? `/${id}` : ""}`, {
        method: id ? "PUT" : "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
        body: JSON.stringify(data),
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      setSnackbar({type: "success", open: true, message: "Success!"});

      if (id) {
        fetchData();
      } else {
        setData(defaultData(fields));
        history.push(`/executiveprofile/${(await res.json()).execid}`);
      }
    } catch (e) {
      console.error(e);
      setSnackbar({type: "error", open: true, message: getError(e)});
    }
  }

  const handleSendUpdateRequest = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/send-oneoff-executive-update-mailer/${id}`, {
        headers: {
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      setSnackbar({type: "success", open: true, message: "Success!"});
    } catch (e) {
      console.error(e);
      setError({type: "error", open: true, message: getError(e)});
    }
  }

  const handleSnackbarClose = () => {
    setSnackbar(DEFAULT_SNACKBAR);
  }

  const handleUploadFinish = (signedUrl) => {
    setData({ ...data, pix: signedUrl.signedUrl.split("?")[0] })
  }

  if (isLoading) {
    return (
      <CircularProgress />
    );
  }

  if (error) {
    return (
      <Alert severity={error.type}>
        {error.message}
      </Alert>
    );
  }

  return (<>
    <Prompt
      when={JSON.stringify(initialData) !== JSON.stringify(data)}
      message="You have unsaved changes, are you sure you want to leave?"
    />

    <Snackbar open={snackbar.open} onClose={handleSnackbarClose} autoHideDuration={DEFAULT_SNACKBAR_TIME}>
      <Alert severity={snackbar.type}>
        {snackbar.message}
      </Alert>
    </Snackbar>

    <Box display="flex" justifyContent="space-between" alignItems="flex-start">
      <Typography variant="h3">
        {id ? "Edit Profile" : "Create New Profile"}
      </Typography>
      {id === undefined && (
        <Box width="30%">
          <Autocomplete options={people} getOptionLabel={(o) => formatName(o.FirstName, o.MiddleName, o.LastName)} renderInput={(params) => <TextField {...params} label="Copy from..." variant="outlined" size="small" />} onChange={(_, o) => o ? setData(o) : null} />
        </Box>
      )}
      {id !== undefined && (
        <Button variant="contained" color="primary" onClick={handleSendUpdateRequest}>Send update request via email</Button>
      )}
    </Box>

    {id !== undefined && (
      <Typography>
        Last Updated: {data.DateofChange}
      </Typography>
    )}

    <Paper className={classes.paper}>
      {fields.map((field, index) => (
        <Box key={index} mb={2}>
          {field === "pix" ? (
            <InputLabel>
              Pix
              <ReactS3Uploader
                signingUrl="/admin/s3-signed-url"
                accept="image/*"
                contentDisposition="auto"
                server={process.env.REACT_APP_API_ENDPOINT}
                signingUrlHeaders={{
                  "Authorization": `Bearer ${googleUser.tokenId}`,
                }}
                s3path="/uploads/"
                uploadRequestHeaders={{}}
                onFinish={handleUploadFinish}
              />
            </InputLabel>
          ) :
            <TextField fullWidth variant="outlined" id={field} label={field} value={data[field]} onChange={(e) => setData({...data, [field]: e.target.value})} />
          }
        </Box>
      ))}
    </Paper>

    <Box mt={2} display="flex" justifyContent="flex-end">
      <Button variant="contained" color="primary" onClick={() => handleSubmit()}>Save Changes</Button>
    </Box>
  </>);
}

export default ExecutiveProfileView;
