import React, { useState, useEffect } from "react";
import { Prompt, useParams, useHistory, Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography, TextField, Box, Snackbar, CircularProgress, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { useGoogleAuth } from "../../contexts/auth";
import { DEFAULT_SNACKBAR, DEFAULT_SNACKBAR_TIME, getError, defaultData } from "../../utils/constants";
import { formatName } from "../../utils/string";
import { DraggableTableRow } from "../../components/DraggableTableRow";

function OfficeView() {
  const { googleUser } = useGoogleAuth();
  const { parent_id, id } = useParams();
  const history = useHistory();
  const fields = ["TheSection", "Division"];

  const [initialData, setInitialData] = useState(defaultData(fields));
  const [officeData, setOfficeData] = useState(defaultData(fields));
  const [officePeopleData, setOfficePeopleData] = useState(undefined);
  const [isLoading, setIsLoading] = useState(id ? true : false);
  const [error, setError] = useState(undefined);
  const [snackbar, setSnackbar] = useState(DEFAULT_SNACKBAR);

  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  const fetchData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/offices/${parent_id}/${id}`, {
        headers: {
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      const resJson = await res.json();
      setInitialData(resJson.office);
      setOfficeData(resJson.office);
      setOfficePeopleData(resJson.people);
    } catch (e) {
      console.error(e);
      setError({type: "error", open: true, message: getError(e)});
    } finally {
      setIsLoading(false);
    }
  }

  const handleSubmit = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/offices/${encodeURIComponent(parent_id)}/${encodeURIComponent(id)}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
        body: JSON.stringify({
          officeData,
          officePeopleData: officePeopleData.map((p, i) => ({ id: p.ID, sortOrder: i }))
         }),
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      setSnackbar({type: "success", open: true, message: "Success!"});
      setInitialData(officeData);
      history.push(`/office/${encodeURIComponent(officeData.TheSection)}/${officeData.Division}`);
    } catch (e) {
      console.error(e);
      setSnackbar({type: "error", open: true, message: getError(e)});
    }
  }

  const handleSnackbarClose = () => {
    setSnackbar(DEFAULT_SNACKBAR);
  }

  const moveRow = (dragIndex, hoverIndex) => {
    const draggedRow = officePeopleData[dragIndex];
    const dataCopy = [...officePeopleData];
    dataCopy.splice(dragIndex, 1);
    dataCopy.splice(hoverIndex, 0, draggedRow);
    setOfficePeopleData(dataCopy);
  };

  if (isLoading) {
    return (
      <CircularProgress />
    );
  }

  if (error) {
    return (
      <Alert severity={error.type}>
        {error.message}
      </Alert>
    );
  }

  return (<>
    <Prompt
      when={JSON.stringify(initialData) !== JSON.stringify(officeData)}
      message="You have unsaved changes, are you sure you want to leave?"
    />

    <Snackbar open={snackbar.open} onClose={handleSnackbarClose} autoHideDuration={DEFAULT_SNACKBAR_TIME}>
      <Alert severity={snackbar.type}>
        {snackbar.message}
      </Alert>
    </Snackbar>

    <Typography variant="h3">
      {id ? "Edit Office" : "Create New Office"}
    </Typography>

    <Paper className={classes.paper}>
      {fields.map((field, index) => (
        <Box key={index} mb={2}>
          <TextField fullWidth variant="outlined" id={field} label={field} value={officeData[field]} onChange={(e) => setOfficeData({...officeData, [field]: e.target.value})} />
        </Box>
      ))}
    </Paper>

    <Box mt={2} mb={2} display="flex" justifyContent="flex-end">
      <Button variant="contained" color="primary" onClick={() => handleSubmit()}>Save Changes</Button>
    </Box>

    {officePeopleData && officePeopleData.length > 0 && (
      <Box mb={2}>
        <Paper className={classes.paper}>
          <Typography variant="h4">
            Current People
          </Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Position</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <DndProvider backend={HTML5Backend}>
                  {officePeopleData.map((row, index) => (
                    <DraggableTableRow index={index} moveRow={moveRow} hover={true} component={Link} to={`/executiveprofile/${row.ID}`} key={index}>
                      <TableCell>
                        {formatName(row.FirstName, row.MiddleName, row.LastName)}
                      </TableCell>
                      <TableCell>
                        {row.Title}
                      </TableCell>
                    </DraggableTableRow>
                  ))}
                </DndProvider>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    )}

    <Paper className={classes.paper}>
      <Typography variant="h4">
        Add New Person
      </Typography>

      <Box mt={2}>
        <Button component={Link} to={`/executiveprofile?parent_id=${encodeURIComponent(parent_id)}&id=${encodeURIComponent(id)}`} variant="contained" color="primary">Click here</Button>
      </Box>
    </Paper>
  </>);
}

export default OfficeView;
