import React, { useState, useEffect } from "react";
import { Prompt, useParams, useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography, TextField, Box, Snackbar, CircularProgress, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";
import { Alert, Autocomplete } from "@material-ui/lab";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { useGoogleAuth } from "../../contexts/auth";
import Modal from "../../components/Modal";
import { DEFAULT_SNACKBAR, DEFAULT_SNACKBAR_TIME, getError, defaultData } from "../../utils/constants";
import { formatName } from "../../utils/string";
import { DraggableTableRow } from "../../components/DraggableTableRow";

function CommitteeView() {
  const { googleUser } = useGoogleAuth();
  const { id } = useParams();
  const history = useHistory();
  const fields = ["CommitteeName", "PhoneNumber", "RoomNumber"];

  const [initialData, setInitialData] = useState(defaultData(fields));
  const [committeeData, setCommitteeData] = useState(defaultData(fields));
  const [committeePeopleData, setCommitteePeopleData] = useState(undefined);
  const [allPeople, setAllPeople] = useState(undefined);
  const [isLoading, setIsLoading] = useState(id ? true : false);
  const [error, setError] = useState(undefined);
  const [snackbar, setSnackbar] = useState(DEFAULT_SNACKBAR);
  const [newPerson, setNewPerson] = useState({});
  const [open, setOpen] = useState(false);
  const [editingPerson, setEditingPerson] = useState(undefined);

  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  const fetchData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/committees/${id}`, {
        headers: {
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      const res1 = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/legislativeprofiles`, {
        headers: {
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
      });

      if (!res1.ok) {
        throw new Error(await res1.text());
      }
      setAllPeople((await res1.json()));

      const resJson = await res.json();
      setInitialData(resJson.committee);
      setCommitteeData(resJson.committee);
      setCommitteePeopleData(resJson.people);
    } catch (e) {
      console.error(e);
      setError({type: "error", open: true, message: getError(e)});
    } finally {
      setIsLoading(false);
    }
  }

  const handleSubmit = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/committees${id ? `/${id}` : ""}`, {
        method: id ? "PUT" : "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
        body: JSON.stringify({
          committeeData: committeeData,
          committeePeopleData: committeePeopleData ? committeePeopleData.map((p, i) => ({ memberID: p.ID, displayOrder: i })) : []
        }),
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      setSnackbar({type: "success", open: true, message: "Success!"});

      if (id) {
        fetchData();
      } else {
        setCommitteeData(defaultData(fields));
        history.push(`/committee/${(await res.json()).CommitteeID}`);
      }
    } catch (e) {
      console.error(e);
      setSnackbar({type: "error", open: true, message: getError(e)});
    }
  }

  const deleteCommittee = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/committees/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      setSnackbar({type: "success", open: true, message: "Success!"});
      history.push("/committees");
    } catch (e) {
      console.error(e);
      setSnackbar({type: "error", open: true, message: getError(e)});
    }
  }

  const handleSnackbarClose = () => {
    setSnackbar(DEFAULT_SNACKBAR);
  }

  const removeFromCommittee = async (legid) => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/committees/person/${id}/${legid}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${googleUser.tokenId}`,
        }
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      setSnackbar({type: "success", open: true, message: "Success!"});
      handleModalClose();
      fetchData();
    } catch (e) {
      console.error(e);
      setSnackbar({type: "error", open: true, message: getError(e)});
    }
  }

  const addToCommittee = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/committees/person/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
        body: JSON.stringify(newPerson)
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      setSnackbar({type: "success", open: true, message: "Success!"});
      fetchData();
      setNewPerson({});
    } catch (e) {
      console.error(e);
      setSnackbar({type: "error", open: true, message: getError(e)});
    }
  }

  const saveEditingPerson = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/committees/person/${id}/${editingPerson.ID}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
        body: JSON.stringify({ Position: editingPerson.Title })
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      setSnackbar({type: "success", open: true, message: "Success!"});
      fetchData();
    } catch (e) {
      console.error(e);
      setSnackbar({type: "error", open: true, message: getError(e)});
    }
  }

  const handleModalClose = () => {
    setOpen(false);
    setEditingPerson(undefined);
  }

  const renderModal = () => {
    if (open) {
      return (
        <Modal open={open} onClose={handleModalClose}>
          <Typography align="center" variant="h5">
            Editing {formatName(editingPerson.FirstName, editingPerson.MiddleName, editingPerson.LastName)}
          </Typography>

          <Box mt={2} mb={2}>
            <TextField fullWidth variant="outlined" id="editing-person-position" label="Position" value={editingPerson.Title} onChange={(e) => setEditingPerson({...editingPerson, Title: e.target.value})} />
          </Box>

          <Box display="flex" justifyContent="space-between">
            <Button color="secondary" variant="contained" onClick={() => removeFromCommittee(editingPerson.ID)}>Remove from committee</Button>
            <Button color="primary" variant="contained" onClick={() => saveEditingPerson()}>Save changes</Button>
          </Box>
        </Modal>
      )
    }
  }

  const moveRow = (dragIndex, hoverIndex) => {
    const draggedRow = committeePeopleData[dragIndex];
    const dataCopy = [...committeePeopleData];
    dataCopy.splice(dragIndex, 1);
    dataCopy.splice(hoverIndex, 0, draggedRow);
    setCommitteePeopleData(dataCopy);
  };

  if (isLoading) {
    return (
      <CircularProgress />
    );
  }

  if (error) {
    return (
      <Alert severity={error.type}>
        {error.message}
      </Alert>
    );
  }

  return (<>
    {renderModal()}
    <Prompt
      when={JSON.stringify(initialData) !== JSON.stringify(committeeData)}
      message="You have unsaved changes, are you sure you want to leave?"
    />

    <Snackbar open={snackbar.open} onClose={handleSnackbarClose} autoHideDuration={DEFAULT_SNACKBAR_TIME}>
      <Alert severity={snackbar.type}>
        {snackbar.message}
      </Alert>
    </Snackbar>

    <Typography variant="h3">
      {id ? "Edit Committee" : "Create New Committee"}
    </Typography>

    <Paper className={classes.paper}>
      {fields.map((field, index) => (
        <Box key={index} mb={2}>
          <TextField fullWidth variant="outlined" id={field} label={field} value={committeeData[field]} onChange={(e) => setCommitteeData({...committeeData, [field]: e.target.value})} />
        </Box>
      ))}
    </Paper>

    <Box mt={2} mb={2} display="flex" justifyContent="flex-end">
      <Button variant="contained" color="primary" onClick={() => handleSubmit()}>Save Changes</Button>
    </Box>

    {committeePeopleData && committeePeopleData.length > 0 && (
      <Box mb={2}>
        <Paper className={classes.paper}>
          <Typography variant="h4">
            Current People
          </Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Position</TableCell>
                  <TableCell>Party</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <DndProvider backend={HTML5Backend}>
                  {committeePeopleData.map((row, index) => (
                    <DraggableTableRow index={index} moveRow={moveRow} hover={true} onClick={() => { setOpen(true); setEditingPerson(row)}} key={index}>
                      <TableCell>
                        {formatName(row.FirstName, row.MiddleName, row.LastName)}
                      </TableCell>
                      <TableCell>
                        {row.Title}
                      </TableCell>
                      <TableCell>
                        {row.party}
                      </TableCell>
                    </DraggableTableRow>
                  ))}
                </DndProvider>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    )}

    {id !== undefined && (
      <>
        <Paper className={classes.paper}>
          <Typography variant="h4">
            Add New Person
          </Typography>

          <Box mt={2}>
            <Autocomplete options={allPeople} getOptionLabel={(o) => formatName(o.FirstName, o.MiddleName, o.LastName)} renderInput={(params) => <TextField {...params} label="Search" variant="outlined" />} onChange={(_, o) => setNewPerson({ ...newPerson, MemberID: o !== null ? o.legid : undefined })} />
          </Box>
          <Box mt={2}>
            <TextField fullWidth label="Position" variant="outlined" onChange={(e) => setNewPerson({ ...newPerson, Position: e.target.value })} />
          </Box>
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button variant="contained" color="primary" disabled={!newPerson.MemberID} onClick={() => addToCommittee()}>Add</Button>
          </Box>
        </Paper>

        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button variant="contained" color="secondary" onClick={() => deleteCommittee()}>Delete Committee</Button>
        </Box>
      </>
    )}
  </>);
}

export default CommitteeView;
