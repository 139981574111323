import React from "react";
import { Switch, Route, Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { Divider, Container, Drawer, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { Publish as PublishIcon, Email as EmailIcon, Update as UpdateIcon, Note as NoteIcon, Create as CreateIcon, ContactMail as ContactMailIcon, People as PeopleIcon, Gavel as GavelIcon, GroupWork as GroupWorkIcon } from "@material-ui/icons";

import HomePageEntries from "../routes/homepageentries";
import HomePageEntryView from "../routes/homepageentries/HomePageEntryView";
import HomePageFreetext from "../routes/homepagefreetext";
import DistrictCSVUpload from "../routes/districtcsvupload";
import CongressionalDelegationProfiles from "../routes/congressionaldelegationprofiles";
import CongressionalDelegationProfileView from "../routes/congressionaldelegationprofiles/CongressionalDelegationProfileView";
import ExecutiveProfiles from "../routes/executiveprofiles";
import ExecutiveProfileView from "../routes/executiveprofiles/ExecutiveProfileView";
import LegislativeProfiles from "../routes/legislativeprofiles";
import LegislativeProfileView from "../routes/legislativeprofiles/LegislativeProfileView";
import LegislativeLeadership from "../routes/legislativeleadership";
import LegislativeLeadershipPositions from "../routes/legislativeleadershippositions";
import Committees from "../routes/committees";
import CommitteeView from "../routes/committees/CommitteeView";
import Offices from "../routes/offices";
import OfficeView from "../routes/offices/OfficeView";
import Agencies from "../routes/agencies";
import AgencyView from "../routes/agencies/AgencyView";
import DivisionContacts from "../routes/divisioncontacts";
import DivisionContactView from "../routes/divisioncontacts/DivisionContactView";
import AlternateContacts from "../routes/alternatecontacts";
import AlternateContactView from "../routes/alternatecontacts/AlternateContactView";
import CdAlternateContacts from "../routes/cdalternatecontacts";
import CdAlternateContactView from "../routes/cdalternatecontacts/CdAlternateContactView";
import PendingUpdates from "../routes/pendingupdates";
import PendingUpdateView from "../routes/pendingupdates/PendingUpdateView";
import SendBulkMailer from "../routes/sendbulkmailer";
import LegislatorUpdateView from "../routes/legislatorupdates";
import CDUpdateView from "../routes/cdupdates";
import ExecutiveUpdateView from "../routes/executiveupdates";
import Login from "../routes/login";
import Success from "../routes/success";
import ProtectedRoute from "./ProtectedRoute";
import { useGoogleAuth } from "../contexts/auth";

function Navigation() {
  const { isInitialized, isSignedIn } = useGoogleAuth();
  const useStyles = makeStyles((theme) => ({
    drawerPaper: {
      position: "relative",
    },
    appBarSpacer: theme.mixins.toolbar,
    paper: {
      padding: theme.spacing(2),
      display: "flex",
      overflow: "auto",
      flexDirection: "column",
    },
    content: {
      flexGrow: 1,
      height: "100vh",
      overflow: "auto",
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      height: "90%"
    },
  }));

  const classes = useStyles();
  const MAIN_LINKS = [{
    link: "/homepageentries",
    icon: <NoteIcon />,
    text: "News"
  }, {
    link: "/homepagefreetext",
    icon: <CreateIcon />,
    text: "Homepage Freetext"
  }, {
    link: "/districtcsvupload",
    icon: <PublishIcon />,
    text: "District CSV Upload"
  }];

  return (
    <>
    {isSignedIn && (
      <Drawer
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.appBarSpacer} />

        {MAIN_LINKS.map((l, i) => (
          <ListItem key={i} button component={Link} to={l.link}>
            <ListItemIcon>
              {l.icon}
            </ListItemIcon>
            <ListItemText primary={l.text} />
          </ListItem>
        ))}

        <Divider />

        <ListItem button component={Link} to={"/congressionaldelegationprofiles"}>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Congressional Delegation Profiles" />
        </ListItem>
        <ListItem button component={Link} to={"/executiveprofiles"}>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Executive Profiles" />
        </ListItem>
        <ListItem button component={Link} to={"/legislativeprofiles"}>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Legislative Profiles" />
        </ListItem>
        <ListItem button component={Link} to={"/legislativeleadership"}>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Legislative Leadership" />
        </ListItem>

        <Divider />

        <ListItem button component={Link} to={"/committees"}>
          <ListItemIcon>
            <GavelIcon />
          </ListItemIcon>
          <ListItemText primary="Committees" />
        </ListItem>
        <ListItem button component={Link} to={"/agencies"}>
          <ListItemIcon>
            <GroupWorkIcon />
          </ListItemIcon>
          <ListItemText primary="Agencies" />
        </ListItem>
        <ListItem button component={Link} to={"/offices"}>
          <ListItemIcon>
            <GroupWorkIcon />
          </ListItemIcon>
          <ListItemText primary="Offices" />
        </ListItem>

        <Divider />

        <ListItem button component={Link} to={"/divisioncontacts"}>
          <ListItemIcon>
            <ContactMailIcon />
          </ListItemIcon>
          <ListItemText primary="Division Contacts" />
        </ListItem>
        <ListItem button component={Link} to={"/alternatecontacts"}>
          <ListItemIcon>
            <ContactMailIcon />
          </ListItemIcon>
          <ListItemText primary="Alternate Contacts" />
        </ListItem>
        <ListItem button component={Link} to={"/cdalternatecontacts"}>
          <ListItemIcon>
            <ContactMailIcon />
          </ListItemIcon>
          <ListItemText primary="Congressional Delegation Alternate Contacts" />
        </ListItem>

        <Divider />

        <ListItem button component={Link} to={"/pendingupdates"}>
          <ListItemIcon>
            <UpdateIcon />
          </ListItemIcon>
          <ListItemText primary="Pending Updates" />
        </ListItem>
        <ListItem button component={Link} to={"/sendbulkmailer"}>
          <ListItemIcon>
            <EmailIcon />
          </ListItemIcon>
          <ListItemText primary="Send Bulk Mailer" />
        </ListItem>
      </Drawer>
    )}

      <Switch>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
              {isInitialized && (
                <>
                  <Route component={Login} path="/" exact={true} />
                  <Route component={Success} path="/success" />
                  <ProtectedRoute path="/homepageentries">
                    <HomePageEntries />
                  </ProtectedRoute>
                  <ProtectedRoute path="/homepageentry/:id">
                    <HomePageEntryView />
                  </ProtectedRoute>
                  <ProtectedRoute path="/homepagefreetext">
                    <HomePageFreetext />
                  </ProtectedRoute>
                  <ProtectedRoute path="/districtcsvupload">
                    <DistrictCSVUpload />
                  </ProtectedRoute>

                  <ProtectedRoute path="/congressionaldelegationprofiles">
                    <CongressionalDelegationProfiles />
                  </ProtectedRoute>
                  <ProtectedRoute path="/congressionaldelegationprofile/:id?">
                    <CongressionalDelegationProfileView />
                  </ProtectedRoute>
                  <ProtectedRoute path="/executiveprofiles">
                    <ExecutiveProfiles />
                  </ProtectedRoute>
                  <ProtectedRoute path="/executiveprofile/:id?">
                    <ExecutiveProfileView />
                  </ProtectedRoute>
                  <ProtectedRoute path="/legislativeprofiles">
                    <LegislativeProfiles />
                  </ProtectedRoute>
                  <ProtectedRoute path="/legislativeprofile/:id?">
                    <LegislativeProfileView />
                  </ProtectedRoute>
                  <ProtectedRoute path="/legislativeleadership">
                    <LegislativeLeadership />
                  </ProtectedRoute>
                  <ProtectedRoute path="/legislativeleadershippositions">
                    <LegislativeLeadershipPositions />
                  </ProtectedRoute>

                  <ProtectedRoute path="/committees">
                    <Committees />
                  </ProtectedRoute>
                  <ProtectedRoute path="/committee/:id?">
                    <CommitteeView />
                  </ProtectedRoute>
                  <ProtectedRoute path="/agencies">
                    <Agencies />
                  </ProtectedRoute>
                  <ProtectedRoute path="/agency/:id?">
                    <AgencyView />
                  </ProtectedRoute>
                  <ProtectedRoute path="/offices">
                    <Offices />
                  </ProtectedRoute>
                  <ProtectedRoute path="/office/:parent_id?/:id?">
                    <OfficeView />
                  </ProtectedRoute>

                  <ProtectedRoute path="/divisioncontacts">
                    <DivisionContacts />
                  </ProtectedRoute>
                  <ProtectedRoute path="/divisioncontact/:id">
                    <DivisionContactView />
                  </ProtectedRoute>
                  <ProtectedRoute path="/alternatecontacts">
                    <AlternateContacts />
                  </ProtectedRoute>
                  <ProtectedRoute path="/alternatecontact/:id">
                    <AlternateContactView />
                  </ProtectedRoute>
                  <ProtectedRoute path="/cdalternatecontacts">
                    <CdAlternateContacts />
                  </ProtectedRoute>
                  <ProtectedRoute path="/cdalternatecontact/:id">
                    <CdAlternateContactView />
                  </ProtectedRoute>

                  <ProtectedRoute path="/pendingupdates">
                    <PendingUpdates />
                  </ProtectedRoute>
                  <ProtectedRoute path="/pendingupdate/:type/:id">
                    <PendingUpdateView />
                  </ProtectedRoute>
                  <ProtectedRoute path="/sendbulkmailer">
                    <SendBulkMailer />
                  </ProtectedRoute>

                  <Route path="/legislatorupdate/:id">
                    <LegislatorUpdateView />
                  </Route>
                  <Route path="/cdupdate/:id">
                    <CDUpdateView />
                  </Route>
                  <Route path="/executiveupdate/:id">
                    <ExecutiveUpdateView />
                  </Route>
                </>
              )}
          </Container>
        </main>
      </Switch>
    </>
  );
}

export default Navigation;
