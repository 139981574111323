import React, { useState, useEffect } from "react";
import { Prompt, useParams, useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { Button, TextField, Box, Typography, Snackbar, CircularProgress, Paper } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { useGoogleAuth } from "../../contexts/auth";
import { formatName } from "../../utils/string";
import { DEFAULT_SNACKBAR, DEFAULT_SNACKBAR_TIME, getError } from "../../utils/constants";

function CdAlternateContactView() {
  const { googleUser } = useGoogleAuth();
  const history = useHistory();
  const [initialAltContactEmail, setInitialAltContactEmail] = useState("");
  const [data, setData] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const [snackbar, setSnackbar] = useState(DEFAULT_SNACKBAR);

  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
    },
  }));

  const { id } = useParams();

  const classes = useStyles();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/cdalternatecontacts/${id}`, {
        headers: {
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      const resJson = await res.json();

      setInitialAltContactEmail(resJson.altContactEmail);
      setData(data => ({...data, altContactEmail: resJson.altContactEmail, FirstName: resJson.FirstName, MiddleName: resJson.MiddleName, LastName: resJson.LastName}));
    } catch (e) {
      console.error(e);
      setError({type: "error", open: true, message: getError(e)});
    } finally {
      setIsLoading(false);
    }
  }

  const handleSubmit = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/cdalternatecontacts/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
        body: JSON.stringify({
          altContactEmail: data.altContactEmail,
        }),
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      setSnackbar({type: "success", open: true, message: "Success!"});
      setInitialAltContactEmail(data.altContactEmail);
      history.push("/cdalternatecontacts");
    } catch (e) {
      console.error(e);
      setSnackbar({type: "error", open: true, message: getError(e)});
    }
  }

  const handleDelete = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/cdalternatecontacts/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${googleUser.tokenId}`,
        }
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      history.push("/cdalternatecontacts");
    } catch (e) {
      console.error(e);
      setSnackbar({type: "error", open: true, message: getError(e)});
    }
  }

  const handleSnackbarClose = () => {
    setSnackbar(DEFAULT_SNACKBAR);
  }

  if (isLoading) {
    return (
      <CircularProgress />
    );
  }

  if (error) {
    return (
      <Alert severity={error.type}>
        {error.message}
      </Alert>
    );
  }

  return (<>
    <Prompt
      when={data.altContactEmail !== initialAltContactEmail}
      message="You have unsaved changes, are you sure you want to leave?"
    />

    <Snackbar open={snackbar.open} onClose={handleSnackbarClose} autoHideDuration={DEFAULT_SNACKBAR_TIME}>
      <Alert severity={snackbar.type}>
        {snackbar.message}
      </Alert>
    </Snackbar>

    <Typography variant="h3">
      Edit Alternate Contact for {formatName(data.FirstName, data.MiddleName, data.LastName)}
    </Typography>

    <Paper className={classes.paper}>
      <TextField fullWidth variant="outlined" id="altContactEmail" label="Contact Email" value={data.altContactEmail} onChange={(e) => setData(data => ({...data, altContactEmail: e.target.value}))} />
      <Box display="flex" justifyContent="space-between" pt={3}>
        <Button variant="contained" color="secondary" onClick={handleDelete}>Delete</Button>
        <Button variant="contained" color="primary" onClick={handleSubmit}>Update</Button>
      </Box>
    </Paper>
  </>);
}

export default CdAlternateContactView;
